/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import Aliplayer from 'aliyun-aliplayer';
import 'aliyun-aliplayer/build/skins/default/aliplayer-min.css';
import './player.less';

const AliyunLivePlayer = ({ id, index, streamUrl, isAutoplay = false, isMute = false, width='100%', height='100%',onPlayError }) => {
    const playerRef = useRef(null);
    const player = useRef(null);
    useEffect(() => {
        
        player.current = new Aliplayer({
            id,
            width: width,
            height: height,
            source: streamUrl,
            isLive: true,
            // controlBarVisibility: 'never',
            useH5Prism: true,
            useFlashPrism: false,
            autoplay: isAutoplay,
            // mute:isMute,
            autoplayPolicy: {
                fallbackToMute: true, // 有声自动播放失败后，是否降级为静音自动播放，默认为false
                showUnmuteBtn: true, // 静音自动播放时，是否居中显示静音大按钮，默认为true
            },
            skinLayoutIgnore:[
                'controlBar.subtitle', // 隐藏控制条上的字幕
                'controlBar.setting' // 设置
            ],
            rtmpBufferTime: 0,
            showBuffer: 0,          
        }, function (player) {
            console.log(`直播播放器 ${id} 创建成功`);
            player.on('error', () => {
                console.log(`直播播放器 ${id} 播放出错`);
                if (typeof onPlayError === 'function') {
                    onPlayError(id); // 调用回调函数并传递播放器的 id
                }
            });
        });

        return () => {
            if (player.current) {
                console.log('销毁');
                
                player.current.destroy();
            }
        };
    }, [id, streamUrl]);


    return (
        <div 
            ref={playerRef}     
            id={id} 
        ></div>
    );
};

export default AliyunLivePlayer;