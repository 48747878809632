import React, { useEffect } from 'react'
import styles from './style.module.less'
import LangChange from '../LangChange'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { taEnterPageTrack } from '@/common/thinkingdata'
import useHandleHistory from '@/hooks/useHandleHistory'
import { getLangData, openWindow } from '@/utils/utils'
import { IMG_BASE_URL } from '@/config'
import { useNavigates } from '@/store/useNavigates'
import { useTranslation } from 'react-i18next'

const Navigate: React.FC = () => {
  useTranslation()
  const { pathname } = useLocation()
  const { push } = useHandleHistory()

  const { navigates, getNavigates } = useNavigates()

  useEffect(() => {
    getNavigates()
  }, [])

  function handleOtherProject(link: string) {
    openWindow(link)
  }

  return (
    <div className={styles.navigate}>
      {navigates.map((n) => {
        const isActive = n.path === pathname
        if (!n.visible) {
          return null
        }
        return (
          <div
            key={n.path}
            onClick={() => {
              if (n.path.startsWith('/')) {
                push(n.path)
                taEnterPageTrack(n.path, '导航栏')
              } else {
                handleOtherProject(n.path)
              }
            }}
            className={classNames(styles.link, isActive && styles.activeLink)}
          >
            {n.icon && (
              <img src={`${IMG_BASE_URL}/${n.icon}`} className={styles.icon} />
            )}
            {getLangData(n.title)}
          </div>
        )
      })}
      {/* <div
        className={styles.link}
        onClick={() => handleOtherProject('http://localhost:3001/#/best7')}
      >
        MEET48.AI
      </div>
      <div
        className={styles.link}
        onClick={() => handleOtherProject('protocol')}
      >
        发射平台
      </div> */}
      <LangChange />
    </div>
  )
}

export default React.memo(Navigate)
