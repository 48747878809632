import { useEffect, useMemo } from 'react'
import { useUserInfo } from '@/store/useUserInfo'
import { WEB3_TYPE } from '@/constants/user'
import { errorNotice } from '@/common/tip'
import useBNB from './useBNB'
import { SupportToken } from '@/services/wallet'
import { useChainId, useReadContracts } from 'wagmi'
import { WalletAddress } from '@/common/types'
import { decimalAbi, balanceAbi } from '@/abi/erc20'

export function useTokenBalance(list: SupportToken[] = []) {
  const { info } = useUserInfo()
  const chainId = useChainId()
  const { data: bnb, refetch: bnbRefetch, isLoading: isLoadingBnb } = useBNB()

  const erc20Tokens = useMemo(() => {
    return list.filter((item) => item.tokenType === 2 && item.contractAddress)
  }, [list])

  const getContracts = (
    tokens: SupportToken[],
    type: 'balance' | 'decimals'
  ) => {
    return tokens.map((token) => {
      return {
        address: token.contractAddress,
        abi: type === 'balance' ? balanceAbi : decimalAbi,
        functionName: type === 'balance' ? 'balanceOf' : 'decimals',
        args: [(info?.address as WalletAddress) ?? '']
      }
    }) as any[]
  }

  const {
    data: balanceList = [],
    isLoading: isLoadingBalanceList,
    refetch: balanceRefetch
  } = useReadContracts({
    contracts: getContracts(erc20Tokens, 'balance'),
    query: {
      enabled: erc20Tokens.length > 0 && info?.type === WEB3_TYPE
    }
  })

  const {
    data: decimalsList = [],
    isLoading: isLoadingDecimalsList,
    refetch: decimalsRefetch
  } = useReadContracts({
    contracts: getContracts(erc20Tokens, 'decimals'),
    query: {
      enabled: erc20Tokens.length > 0 && info?.type === WEB3_TYPE
    }
  })

  const tokenData = useMemo(() => {
    return erc20Tokens
      .map((token, i) => {
        return {
          coinCode: token.coinCode,
          amount:
            Number(balanceList[i]?.result ?? 0) /
            10 ** Number(decimalsList[i]?.result ?? 18)
        }
      })
      .filter((token) => token.amount > 0)
  }, [erc20Tokens, balanceList, decimalsList])

  async function refetch() {
    bnbRefetch()
    balanceRefetch()
    decimalsRefetch()
  }

  useEffect(() => {
    if (info?.type === WEB3_TYPE) {
      refetch()
    }
  }, [info?.type, chainId])

  function handleRefetch() {
    const start = +new Date()
    const timer = setInterval(async () => {
      try {
        if (start + 5000 > +new Date()) {
          await refetch()
          clearInterval(timer)
        } else {
          clearInterval(timer)
        }
      } catch (error: any) {
        errorNotice(error)
      }
    }, 1000)
  }

  return {
    tokenData,
    bnb,
    refetch: handleRefetch,
    isLoading: isLoadingBnb || isLoadingBalanceList || isLoadingDecimalsList
  }
}
