import { service } from '../http'
import { translate } from './model'
export class AgentLiveApi {
  // 获取智能体直播
  static async agentLive(){
    return service.post(`/live/api/v1/live/agent_live_list`)
  }
  // 获取云信
  static async getImInfo(){
    return service.get(`/user/api/v1/im/getUserImAccount`)
  }
  // 翻译
  static async toTranslate(data:translate){
    return service.post(`/idol-answer/api/idolanswer/v1/translate`,data)
  }
}