import { errorNotice } from '@/common/tip'
import { useUserInfo } from '@/store/useUserInfo'
import { useAccount, useConnect, useDisconnect } from 'wagmi'

export default function useWalletConnect() {
  const { connectAsync, connectors } = useConnect()
  const { info } = useUserInfo()

  const metamaskConnector = connectors.find((c) => c.name === 'MetaMask')
  const okxConnector = connectors.find((c) => c.name === 'OKX Wallet')
  const bitgetConnector = connectors.find((c) => c.name === 'Bitget Wallet')

  function getConnector(type?: number) {
    if (type === 3) {
      return metamaskConnector
    }
    if (type === 4) {
      return okxConnector
    }
    if (type === 9) {
      return bitgetConnector
    }
    return null
  }

  const { disconnect } = useDisconnect()
  const { isConnected } = useAccount() // 帐户信息

  function handleError(error: any) {
    errorNotice(error)
    if (isConnected) {
      disconnect()
    }
  }

  const onConnect = async () => {
    if (isConnected) {
      return true
    }
    const connector = getConnector(info?.loginType)
    if (!connector) {
      return false
    }
    try {
      await connectAsync({ connector })
      return true
    } catch (error: any) {
      handleError(error)
      return false
    }
  }

  return { onConnect }
}
