import React, { useMemo, useState } from 'react'
import Total from './Total'
import AssetsDetail from './AssetsDetail'
import { useTokenBalance } from '@/hooks/web3/coin/useTokenBalance'
import useQuery from '@/hooks/useQuery'
import { WalletApi } from '@/services/wallet'
import useDiamondPoints from '@/hooks/web3/token/useDiamondPoints'
import Web3AssetsSkeleton from './Web3AssetsSkeleton'
import { useChainId } from 'wagmi'
import { SupportToken } from '@/services/wallet'

function getUsd(tokenList: SupportToken[], code: string) {
  const usd = tokenList.find((token) => token.coinCode === code)?.usd
  return usd ? +usd : 0
}

const Web3Assets: React.FC = () => {
  const [showAsset, setShowAsset] = useState(true)
  function toggle() {
    setShowAsset((s) => !s)
  }
  const chainId = useChainId()

  const { data: tokenList = [] } = useQuery(WalletApi.getTokenSupportList, [
    chainId
  ])
  const {
    tokenData,
    bnb,
    isLoading: isLoadingCoin
  } = useTokenBalance(tokenList)
  const {
    diamondAmount,
    diamondUSDValue,
    pointsAmount,
    pointsUSDValue,
    loading: isLoadingDiamond
  } = useDiamondPoints()

  const totalUsd = useMemo(() => {
    const tokenBalanceTotal = tokenData.reduce((prev, token) => {
      return prev + token.amount * getUsd(tokenList, token.coinCode)
    }, 0)
    const total =
      tokenBalanceTotal +
      bnb * getUsd(tokenList, 'BNB') +
      diamondUSDValue +
      pointsUSDValue
    return total.toFixed(4)
  }, [tokenData, bnb, diamondUSDValue, pointsUSDValue])

  if (isLoadingCoin || isLoadingDiamond) return <Web3AssetsSkeleton />

  return (
    <>
      <Total showAsset={showAsset} toggle={toggle} totalUsd={totalUsd} />
      <AssetsDetail
        showAsset={showAsset}
        assets={{
          bnb,
          diamond: Number(diamondAmount),
          points: Number(pointsAmount),
          diamondUsdValue: diamondUSDValue,
          pointsUsdValue: pointsUSDValue
        }}
        tokenData={tokenData}
        tokenList={tokenList}
      />
    </>
  )
}

export default React.memo(Web3Assets)
