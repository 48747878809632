import { useReadContract } from 'wagmi'
import { useMemo } from 'react'
import { useUserInfo } from '@/store/useUserInfo'
import { errorNotice } from '@/common/tip'
import { WalletAddress } from '@/common/types'
import { WEB3_TYPE } from '@/constants/user'
import { decimalAbi, balanceAbi } from '@/abi/erc20'

export default function useUSDT(contractAddress?: WalletAddress) {
  const { info } = useUserInfo()

  const {
    data: balance = 0,
    refetch: refetchBalance,
    isLoading
  } = useReadContract({
    address: contractAddress,
    abi: balanceAbi,
    functionName: 'balanceOf',
    args: [(info?.address as WalletAddress) ?? ''],
    query: {
      enabled: !!contractAddress && info?.type === WEB3_TYPE
    }
  })

  const {
    data: decimals = 6,
    refetch: refetchDecimals,
    isLoading: isLoadingDecimals
  } = useReadContract({
    address: contractAddress,
    abi: decimalAbi,
    functionName: 'decimals',
    args: [(info?.address as WalletAddress) ?? ''],
    query: {
      enabled: !!contractAddress && info?.type === WEB3_TYPE
    }
  })

  const data = useMemo(() => {
    return Number(balance) / 10 ** Number(decimals)
  }, [balance, decimals])

  async function refetch() {
    return Promise.all([refetchBalance(), refetchDecimals()])
  }

  function handleRefetch() {
    const start = +new Date()
    const timer = setInterval(async () => {
      try {
        if (start + 5000 > +new Date()) {
          await refetch()
          clearInterval(timer)
        } else {
          clearInterval(timer)
        }
      } catch (error: any) {
        errorNotice(error)
      }
    }, 1000)
  }

  return {
    data,
    refetch: handleRefetch,
    isLoading: isLoading || isLoadingDecimals
  }
}
