import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './style.module.less'
import { GoBackTitle } from '../../components'
import Module from '@/components/Module'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AssetInput from '../AssetInput'
import { errorNotice, notice, web3ErrorNotice } from '@/common/tip'
import { Button } from '@/components/Button'
import { DarkInfoIcon } from '@/assets'
import { useUserInfo } from '@/store/useUserInfo'
import { WEB2_TYPE } from '@/constants/user'
import { NotifyHashParams, PaymentApi } from '@/services/payment'
import { useModal } from '@/store/useModal'
import RateChangeModal from '../RateChangeModal'
import { useRecharge } from '@/hooks/web3/useRecharge'
import { getLocalItem, setLocalItem } from '@/common/storage'
import { Tooltip } from 'antd'
import useRate from '../hooks/useRate'
import useBalance from '../hooks/useBalance'
import useTokenList from '../hooks/useTokenList'

export type Coin = {
  code: string
  icon: string
}

const KEY = 'diamond-recharge-coin'

const RechargeForm: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { info } = useUserInfo()
  const [loading, setLoading] = useState(false)

  const { writeHandle } = useRecharge((error) => {
    web3ErrorNotice(error)
    setLoading(false)
  })

  async function notifyHash(params: NotifyHashParams) {
    setLoading(true)
    const res = await PaymentApi.notifyHash(params)
    setLoading(false)
    if (res.data.code === 0) {
      handleSuccess()
    } else {
      errorNotice(res.data.message)
    }
  }

  function handleSuccess() {
    notice(t('wallet.depositform.diamondRechargeSuccess'), 'success')
    setLocalItem(KEY, sendCoin)
    history.push('/profile/assets')
  }

  const [sendCoin, setSendCoin] = useState(getLocalItem(KEY) || 'USDT')

  const [sendAmount, setSendAmount] = useState('')
  const [getAmount, setGetAmount] = useState('')

  const { openAsync } = useModal()

  const { rate, rateLoading } = useRate(sendCoin)

  const { tokenList, coinList } = useTokenList()
  const balance = useBalance(sendCoin, tokenList)

  function handleCoinChange(code: string) {
    setSendAmount('')
    setGetAmount('')
    setSendCoin(code)
  }

  function handleSendAmountChange(amount: string) {
    if (!amount) {
      setSendAmount('')
      setGetAmount('')
    }
    if (!/^\d+\.?\d*$/.test(amount)) {
      return
    }
    const diamondAmount = Math.floor(+amount * rate)
    setSendAmount(amount.includes('.') ? amount : `${+amount}`)
    setGetAmount(`${diamondAmount}`)
  }

  function handleGetAmountChange(amount: string) {
    if (Number.isInteger(+amount)) {
      setGetAmount(`${+amount}`)
      setSendAmount(`${+amount / rate}`)
    } else {
      setGetAmount('')
      setSendAmount('')
    }
  }

  function onMax() {
    setSendAmount(`${balance}`)
    setGetAmount(`${Math.floor(balance * rate)}`)
  }

  async function handleCreate(diamondAmountTotal = +getAmount) {
    const token = tokenList.find((item) => item.coinCode === sendCoin)
    if (!token) {
      return
    }
    const { id, contractAddress, tokenType } = token
    setLoading(true)
    const res = await PaymentApi.createDigitalOrder({
      totalAmount: +sendAmount,
      diamondAmountTotal,
      currency: sendCoin,
      tokenId: id,
      contractAddress: contractAddress,
      tokenType: tokenType
    })
    setLoading(false)
    if (res.data.code === 0) {
      if (res.data.data.needDialog) {
        const confirm = await openAsync(
          <RateChangeModal num={res.data.data.diamondAmountTotal} />
        )
        if (confirm) {
          await handleCreate(res.data.data.diamondAmountTotal)
        }
      } else {
        if (info?.type === WEB2_TYPE) {
          handleSuccess()
        } else {
          const { outTradeNo, tradeNo } = res.data.data.createOrderRespDto
          const { chainId, totalAmount, toAddress } = res.data.data.payOrder
          setLoading(true)
          await writeHandle(
            {
              chainId,
              contract: contractAddress,
              tokenType,
              amount: totalAmount,
              to: toAddress
            },
            (hash) => {
              notifyHash({ outTradeNo, tradeNo, hash })
            }
          )
        }
      }
    } else {
      errorNotice(res.data.message)
    }
  }

  async function submit() {
    if (!info) {
      notice(t('task.logFirst'))
      return
    }
    if (+sendAmount > balance) {
      notice(t('normal.insufficientBalance'))
      return
    }
    await handleCreate()
  }

  const disabled = useMemo(() => {
    return balance === 0 || +getAmount === 0 || rateLoading || loading
  }, [balance, getAmount, rateLoading, loading])

  return (
    <Module className={styles.recharge}>
      <GoBackTitle
        title={t('wallet.assets.depositDiamond')}
        onClick={history.goBack}
      />
      <AssetInput
        label={t('wallet.assets.youSend')}
        list={coinList}
        extra={
          <div>
            {t('wallet.assets.yourBalance')}:{' '}
            <span className={styles.unit}>
              {balance} {sendCoin}
            </span>
          </div>
        }
        hasClear={false}
        hasMax
        value={sendAmount}
        code={sendCoin}
        onChange={handleSendAmountChange}
        codeChange={handleCoinChange}
        onMax={onMax}
        disabled={rateLoading}
        placeholder={t('wallet.form.amountTip')}
      />
      <AssetInput
        disabled
        label={t('wallet.assets.youGet')}
        hasClear={false}
        list={[
          {
            code: 'Diamond',
            icon: 'https://static.meet48.xyz/image/varietyCover/2024/09/371b88a1-24c7-4a6d-9d28-61f18574ecf0.png'
          }
        ]}
        code={'Diamond'}
        value={getAmount}
        onChange={handleGetAmountChange}
        extra={
          <div className={styles.extra}>
            1 {sendCoin}≈{rateLoading ? '-' : Math.floor(rate)}{' '}
            <span style={{ textTransform: 'capitalize' }}>
              {t('wallet.assets.diamond')}
            </span>{' '}
            <Tooltip title={t('wallet.assets.diamondRateTip')}>
              <img src={DarkInfoIcon} className={styles.infoIcon} />
            </Tooltip>
          </div>
        }
      />
      <Button className={styles.btn} onClick={submit} disabled={disabled}>
        {t('wallet.assets.depositDiamond')}
      </Button>
    </Module>
  )
}

export default React.memo(RechargeForm)
