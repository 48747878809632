import React, { useMemo, useState } from 'react'
import styles from './style.module.less'
import { Space } from 'antd'
import { ProfileTable } from '@/components/Table'
import { TextButton, ProfileButton } from '@/components/Button'
import Module from '@/components/Module'
import InputBox from '@/components/InputBox'
import type { TableProps } from 'antd'
import { useHistory } from 'react-router-dom'
import { SupportToken, WalletApi } from '@/services/wallet'
import { round } from '@/utils/utils'
import Checkbox from '@/components/Checkbox'
import Empty from '@/components/Empty'
import { useTranslation } from 'react-i18next'
import { useAssets } from '@/store/useAssets'
import { POINT_COIN_CODE } from '@/constants/vote'
import { PointIcon } from '@/assets'
import { SortOrder } from 'antd/lib/table/interface'
import { DIAMOND_ICON } from '@/constants/profile'
import useQuery from '@/hooks/useQuery'
import classNames from 'classnames'

const { SearchInput } = InputBox

type TokenDataItem = {
  coinCode: string
  amount: number
}

type Props = {
  showAsset: boolean
  assets: {
    bnb: number
    diamond: number
    points: number
    diamondUsdValue: number
    pointsUsdValue: number
  }
  tokenList: SupportToken[]
  tokenData: TokenDataItem[]
}

type Item = {
  crypto: string
  code: string
  amount: number
  dollar: number
  icon: string
}

const filterUSDT = (list: TokenDataItem[]) => {
  const usdt = list.find((item) => item.coinCode === 'USDT')
  const others = list.filter((item) => item.coinCode !== 'USDT')
  return { usdt, others }
}

const AssetsDetail: React.FC<Props> = ({
  showAsset,
  assets,
  tokenList,
  tokenData
}) => {
  const { t } = useTranslation()
  const [hide, setHide] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [sortOrder, setSortOrder] = useState<{
    columnKey: string | undefined
    order: SortOrder
  }>({ columnKey: undefined, order: null })

  const { data: supportList = [] } = useQuery(WalletApi.getCoinSupportList)

  const iconMap = useMemo(() => {
    return supportList.reduce(
      function (prev, item) {
        return { ...prev, [item.coinCode]: item.coinImg }
      },
      {} as Record<string, string>
    )
  }, [supportList])

  const { getAssetByName } = useAssets()

  const handleTableChange = (_: any, __: any, sorter: any) => {
    if (sorter.columnKey === 'crypto') {
      setSortOrder({ columnKey: 'crypto', order: sorter.order })
    } else if (sorter.columnKey === 'dollar') {
      setSortOrder({ columnKey: 'dollar', order: sorter.order })
    } else {
      setSortOrder({ columnKey: undefined, order: null })
    }
  }

  const originDataSource = useMemo(() => {
    function getUsd(code: string) {
      const usd = tokenList.find((token) => token.coinCode === code)?.usd
      return usd ? +usd : 0
    }
    const { usdt, others } = filterUSDT(tokenData)
    return [
      {
        crypto: 'BNB',
        amount: assets.bnb,
        code: 'BNB',
        dollar: Number(
          (getUsd('BNB') * assets.bnb).toFixed(4).replace(/\.?0*$/, '')
        ),
        icon: iconMap['BNB']
      },
      {
        crypto: 'USDT',
        amount: round(usdt?.amount ?? 0, 4),
        code: 'USDT',
        dollar: Number(
          (getUsd('USDT') * (usdt?.amount ?? 0))
            .toFixed(4)
            .replace(/\.?0*$/, '')
        ),
        icon: iconMap['USDT']
      },
      {
        crypto: 'Diamond',
        amount: assets.diamond,
        code: 'Diamond',
        dollar: assets.diamondUsdValue,
        icon: DIAMOND_ICON
      },
      {
        crypto: 'M Point',
        amount: getAssetByName(POINT_COIN_CODE),
        code: 'M Point',
        dollar: assets.pointsUsdValue,
        icon: PointIcon
      },
      ...others.map((item) => {
        return {
          crypto: item.coinCode,
          amount: round(item.amount, 4),
          code: item.coinCode,
          dollar: Number(
            (getUsd(item.coinCode) * item.amount)
              .toFixed(4)
              .replace(/\.?0*$/, '')
          ),
          icon: iconMap[item.coinCode]
        }
      })
    ]
  }, [assets, tokenList, tokenData, iconMap])

  const dataSource = useMemo(() => {
    let list: Item[] = originDataSource
    if (hide) {
      list = originDataSource.filter((item) => item.dollar >= 1)
    }
    return list.filter((item) =>
      item.crypto.toLowerCase().includes(keyword.trim().toLowerCase())
    )
  }, [originDataSource, hide, keyword])

  const history = useHistory()
  function to(path: string) {
    history.push(path)
  }

  function handleClear() {
    setHide(false)
    setKeyword('')
    setSortOrder({ columnKey: undefined, order: null })
  }

  const columns: TableProps<Item>['columns'] = [
    {
      title: t('wallet.currency'),
      dataIndex: 'crypto',
      key: 'crypto',
      width: 250,
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => a.crypto.localeCompare(b.crypto),
        multiple: 3
      },
      sortOrder: sortOrder.columnKey === 'crypto' ? sortOrder.order : null,
      render(val, record) {
        return (
          <div className={styles.kindCtn}>
            <img src={record.icon} className={styles.currencyIcon} />
            <div className={styles.texts}>
              <div className={styles.kind}>{record.crypto}</div>
              <div>{record.code}</div>
            </div>
          </div>
        )
      }
    },
    {
      title: t('normal.amount'),
      dataIndex: 'dollar',
      key: 'dollar',
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          return a.dollar - b.dollar
        },
        multiple: 3
      },
      sortOrder: sortOrder.columnKey === 'dollar' ? sortOrder.order : null,
      render(val, record) {
        return (
          <div className={styles.amountCtn}>
            <div>{showAsset ? `${record.amount}` : '****'}</div>
            <div className={styles.amount}>
              {showAsset ? `$${record.dollar}` : '****'}
            </div>
          </div>
        )
      }
    },
    {
      title: '',
      dataIndex: 'code',
      key: 'code',
      width: 200,
      render(val, record) {
        if (record.code === 'Diamond') {
          return (
            <TextButton
              className={classNames(styles.tableBtn, styles.depositBtn)}
              onClick={() => to(`/profile/diamond-recharge`)}
            >
              {t('wallet.assets.depositDiamond')}
            </TextButton>
          )
        }
        return null
      }
    }
  ]

  return (
    <Module className={styles.assetsDetail}>
      <div className={styles.form}>
        <div className={styles.left}>
          <div className={styles.searchTitle}>
            {t('wallet.assets.myAssets')}
          </div>
          <Space size={8}>
            <Checkbox checked={hide} onChange={(c) => setHide(c)} />
            <span
              className={styles.hideTip}
            >{`${t('wallet.assets.hideAssets')}< 1 USDT`}</span>
          </Space>
        </div>
        <Space>
          <SearchInput
            placeholder={t('normal.search')}
            className={styles.searchInput}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          <ProfileButton type="button" onClick={handleClear}>
            {t('normal.reset')}
          </ProfileButton>
        </Space>
      </div>
      <div className={styles.divider}></div>
      <Empty data={dataSource}>
        <ProfileTable
          className={styles.table}
          rowKey={'code'}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          onChange={handleTableChange}
        />
      </Empty>
    </Module>
  )
}

export default React.memo(AssetsDetail)
