import {
  useWriteContract,
  useWaitForTransactionReceipt,
  useSwitchChain,
  useSendTransaction
} from 'wagmi'
import { HookOnError, SuccessCallback, WalletAddress } from '@/common/types'
import useCheckAccount from './useCheckAccount'
import { getAccount } from 'wagmi/actions'
import { config } from '@/config/wagmi'
import { useEffect, useRef } from 'react'

const abi = [
  {
    constant: false,
    inputs: [
      {
        name: '_to',
        type: 'address'
      },
      {
        name: '_value',
        type: 'uint256'
      }
    ],
    name: 'transfer',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  }
]

type Params = {
  chainId: number
  contract: WalletAddress
  tokenType: number // 1是链币 2 erc20 币
  amount: string
  to: WalletAddress
}

function useSendBNB(onError: HookOnError) {
  const { data, sendTransactionAsync } = useSendTransaction()

  const { isLoading, isSuccess, error } = useWaitForTransactionReceipt({
    hash: data
  })

  useEffect(() => {
    if (error) {
      onError(error)
    }
  }, [error])

  async function send(params: Params) {
    const { to, amount } = params
    await sendTransactionAsync(
      {
        to,
        value: BigInt(+amount)
      },
      {
        onError
      }
    )
  }

  return { data, isLoading, isSuccess, send }
}

function useSendUSDT(onError: HookOnError) {
  const { data, writeContract } = useWriteContract()

  const { isLoading, isSuccess, error } = useWaitForTransactionReceipt({
    hash: data
  })

  useEffect(() => {
    if (error) {
      onError(error)
    }
  }, [error])

  async function send(params: Params) {
    const { contract, to, amount } = params
    await writeContract(
      {
        address: contract,
        abi,
        functionName: 'transfer',
        args: [to, BigInt(+amount)]
      },
      {
        onError
      }
    )
  }

  return { data, isLoading, isSuccess, send }
}

export function useRecharge(onError: HookOnError) {
  const checkAccount = useCheckAccount()
  const onSuccess = useRef<SuccessCallback>()

  const bnbObj = useSendBNB(onError)
  const usdtObj = useSendUSDT(onError)

  const { switchChainAsync } = useSwitchChain()

  const hash = bnbObj.data || usdtObj.data
  const isSuccess = bnbObj.isSuccess || usdtObj.isSuccess

  useEffect(() => {
    if (isSuccess && hash) {
      onSuccess.current?.(hash)
    }
  }, [isSuccess, hash])

  async function handleTransfer(
    params: Params,
    successCallback: SuccessCallback
  ) {
    try {
      await checkAccount()
      onSuccess.current = successCallback
      const { chainId } = getAccount(config)
      if (chainId !== params.chainId) {
        await switchChainAsync({ chainId: params.chainId })
      }
      if (params.tokenType === 1) {
        await bnbObj.send(params)
      } else {
        await usdtObj.send(params)
      }
    } catch (e) {
      onError(e)
    }
  }

  return {
    isLoading: bnbObj.isLoading || usdtObj.isLoading,
    writeHandle: handleTransfer
  }
}
