import { useNavigates } from '@/store/useNavigates'
import { useSSOLogin } from '@/store/useSSOLogin'
import { useUserInfo } from '@/store/useUserInfo'
import React, { useMemo } from 'react'
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'

type Props = {
  checkNavigate?: boolean
} & RouteProps

const CustomRoute: React.FC<Props> = ({ checkNavigate = false, ...props }) => {
  const { navigates, hasGotten } = useNavigates()
  const { info } = useUserInfo()
  const { isChecked } = useSSOLogin()
  const { pathname } = useLocation()
  const isActive = pathname === props.path

  const checkLogin = useMemo(() => {
    if (props.path === undefined) {
      return false
    }
    if (typeof props.path === 'string') {
      return props.path.startsWith('/profile')
    }
    return props.path.some((item) => item.startsWith('/profile'))
  }, [props.path])

  if (checkLogin || checkNavigate) {
    if (!isActive) {
      return <Route {...props} />
    }
    if (checkNavigate && !hasGotten) {
      return null
    }
    if (checkNavigate && hasGotten) {
      if (!navigates.some((n) => n.path === props.path && n.visible)) {
        return <Redirect to="/" />
      }
    }
    if (checkLogin && !isChecked) {
      return null
    }
    if (checkLogin && isChecked) {
      if (!info) {
        return <Redirect to="/" />
      }
    }
  }

  return <Route {...props} />
}

export default React.memo(CustomRoute)
