/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { AgentLiveApi } from '@/services/agentLive';
import { useTranslation } from 'react-i18next';
import Aliplayer from './aliplayer'; 
import SDK from '@yxim/nim-web-sdk'
import snedIcon from '@/assets/images/agentLive/sendIcon.png'
import liveCover from '@/assets/images/agentLive/liveCover.jpg'
import liveCoverEn from '@/assets/images/agentLive/liveCoverEn.jpg'
import toAiEn from '@/assets/images/agentLive/toAiEn.png'
import toAi from '@/assets/images/agentLive/toAi.png'
import { Modal } from 'antd';
import { useUserInfo } from '@/store/useUserInfo'
import './live-room.less';

const LiveRoom = () => {

    const { t, i18n } = useTranslation();
    const [liveRoomInfo, setLiveRoomInfo] = useState({}); 
    const appKey = '02aaae95436af7d5f98a7640a1964a12';
    const [imInfo, setImInfo] = useState({});
    const [inputValue, setInputValue] = useState('');
    const [nim, setNim] = useState(null);
    const [chatroom, setChatroom] = useState(null);
    const [messages, setMessages] = useState([]);
    const [showSelectBox, setShowSelectBox] = useState(false);
    const scrolMsglRef = useRef(null);
    const [lastSendTime, setLastSendTime] = useState(0);
    const [isSending, setIsSending] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [liveType, setLiveType] = useState(true);
    const [showProgram, setShowProgram] = useState(false);
    const [angetDetailUrl, setAngetDetailUrl] = useState('');
    const [limitTime, setLimitTime] = useState(0);
    const { info } = useUserInfo()
    const getLocal = () => {
        const userLocal = sessionStorage.getItem('userInfo');
        const userParse = userLocal && JSON.parse(userLocal);
        return userParse;
    };

    // 直播详情
    const getLiveRoom = () => {
        AgentLiveApi.agentLive().then(res => {
            if (res.data.code != 0) return;
            const data = res?.data?.data?.content[0]; 

            if (data) {
                setAngetDetailUrl(res?.data?.data?.angetDetailUrl);
                setLimitTime(res?.data?.data?.limitTime);
                const now = Date.now();
                const startTime = res?.data?.data?.startTime;
                const endTime = res?.data?.data?.endTime;
                const isLive = now >= startTime && now <= endTime;
                
                setLiveType(isLive);
                setLiveRoomInfo({...data});
            }else{
                setLiveType(false);
            }
        }); 
    }

    //获取云信配置
    const getNIMConfig = () => {
        AgentLiveApi.getImInfo().then(res => {
            if (res.data.code != 0) return;
            const data = res?.data?.data; 
            setImInfo({...data});
        }); 
    }

    useEffect(() => {
        if (!info) return;
        getNIMConfig();
    }, [info]);

    useEffect(() => {
        getLiveRoom();
        if (!info) return;
        getNIMConfig();
    }, []);

    const initIM = () => {
        if (Object.keys(imInfo).length <= 0) return;

        const nimInstance = SDK.NIM.getInstance({
            appKey: appKey,
            account: imInfo.accid,
            token: imInfo.token,
            debug: true,
            onconnect: () => {
                console.log('IM 连接成功');
                nimInstance.getChatroomAddress({
                    chatroomId: liveRoomInfo?.roomUid,
                    done: getChatroomAddressDone
                }); // 获取聊天室地址
            },
            ondisconnect: (error) => {
                console.log('连接断开', error);
            },
            onerror: (error) => {
                console.log('发生错误', error);
            },
            onmsg: (msg) => {
                console.log('收到消息', msg);
            },
            onRoamingMsgs: (obj) => {
                console.log('收到漫游消息', obj);
            },
            onOfflineMsgs: (obj) => {
                console.log('收到离线消息', obj);
            }
        });

        setNim(nimInstance);
    };

    useEffect(() => {
        
        if (liveRoomInfo?.roomUid) {
            initIM();
        }
    }, [imInfo,liveRoomInfo.roomUid]);

    //连接聊天室
    const getChatroomAddressDone = (error, obj) => {
        console.log('获取聊天室地址' + (!error?'成功':'失败'), error, obj);
        if(!error){
            const chatroomaddress = obj.address[0];
            console.log(chatroomaddress);
            
            cheateChatroom(chatroomaddress);
        }
    }

    // 创建聊天室
    const cheateChatroom = (address) => {
        console.log('创建聊天室');
        
        const chatroomInfo = SDK.Chatroom.getInstance({
            appKey: appKey,
            account:imInfo.accid,
            token:imInfo.token,
            chatroomId: liveRoomInfo?.roomUid,
            chatroomAddresses: [
                address
            ],
            // onconnect: onChatroomConnect,
            onconnect: (obj) => {
                console.log('进入聊天室', obj);
                chatroomInfo.getChatroom({
                    done: getChatroomDone
                });
                chatroomInfo.getHistoryMsgs({
                    limit: 50,
                    msgTypes: ['text'],
                    done: getHistoryMsgsDone
                });
            },
            onerror: onChatroomError,
            onwillreconnect: onChatroomWillReconnect,
            ondisconnect: onChatroomDisconnect,
            // 消息
            onmsgs: onChatroomMsgs
        });

        setChatroom(chatroomInfo);

    }

    // 获取聊天室信息
    const getChatroomDone = (error, obj) => {
        console.log('获取聊天室信息' + (!error?'成功':'失败'), error, obj);
    }
    // 获取聊天室历史消息
    const getHistoryMsgsDone = (error, obj) => {
        console.log('获取聊天室历史消息' + (!error?'成功':'失败'), error, obj);
        if (!error && obj.msgs && obj.msgs.length > 0) {
            const reversedMsgs = obj.msgs.slice();
            reversedMsgs.forEach((msg) => {
                filterMessages(msg,msg.flow=='out'?true:false);
            });
            
        }
    }
    //聊天室发生错误    
    const onChatroomError = (error) => {
        console.log('聊天室发生错误', error);
    };
    //聊天室重连
    const onChatroomWillReconnect = (obj) => {
        console.log('即将重连', obj);
    };
    //聊天室被踢出
    const onChatroomDisconnect = (error) => {
        console.log('聊天室被踢出', error);
    };
    //收到聊天室消息
    const onChatroomMsgs = (obj) => {
        if(obj.length >= 0 && obj[0].custom){
            filterMessagesSend(obj[0]);
        }
    };

    //消息过滤
    const filterMessages = (msg, isSendMsg = false) => {
        if (msg.type === 'text' && msg.text != "") {
            let avatar = null;
            try {
                const customData = JSON.parse(msg.custom);
                const user = customData.user;
                if (user && user.avatar) {
                    avatar = user.avatar.indexOf('http') !== -1 ? user.avatar : `https://static.meet48.xyz/${user.avatar}`;
                }
            } catch (error) {
                console.error('解析 msg.custom 时出错:', error);
            }

            if (avatar) {
                const newMessage = {
                    avatar,
                    text: msg.text,
                    isSendMsg: isSendMsg
                };
                setMessages((prevMessages) => [...prevMessages, newMessage]);
            }else{
                const newMessage = {
                    avatar: '',
                    text: msg.text,
                    isSendMsg: isSendMsg
                };
                setMessages((prevMessages) => [...prevMessages, newMessage]);
            }
            return false;
        }
        return false;
    };

    // 发送消息过滤
    const filterMessagesSend = (msg, isSendMsg = false) => {
        if (msg.type === 'text' && msg.text != "") {
            let avatar = null;
            try {
                const customData = JSON.parse(msg.custom);
                const user = customData.user;
                if (user && user.avatar) {
                    avatar = user.avatar.indexOf('http') !== -1 ? user.avatar : `https://static.meet48.xyz/${user.avatar}`;
                }
            } catch (error) {
                console.error('解析 msg.custom 时出错:', error);
            }

            if (avatar) {
                const newMessage = {
                    avatar,
                    text: msg.text,
                    isSendMsg: isSendMsg
                };
                setMessages((prevMessages) => [newMessage,...prevMessages]);
            }else{
                const newMessage = {
                    avatar: '',
                    text: msg.text,
                    isSendMsg: isSendMsg
                };
                setMessages((prevMessages) => [newMessage,...prevMessages]);
            }
            return false;
        }
        return false;
    };

    // 发送消息
    const handleSendMessage = () => {
        const currentTime = Date.now();
        const timeDiff = currentTime - lastSendTime;
        if (timeDiff < limitTime*1000) return
        const user = getLocal();
        
        if (nim && chatroom && inputValue.trim()!== '') {
            let obj = {
                "module": 'LIVE',
                "liveId":liveRoomInfo?.liveId,
                "user": {
                    "userId": user?.uid,
                    "userName": user?.userName,
                    "avatar": user?.avatar,
                },
                "businessData":{
                    "bussnessId":liveRoomInfo?.liveId,
                    "agentLiveRoomId":liveRoomInfo?.agentLiveRoomId,
                }
            }
            chatroom.sendText({
                text: inputValue,
                antiSpamUsingYidun:true,
                yidunEnable: true,
                antiSpamContent: JSON.stringify({"type": 1,"data": inputValue}),
                custom:JSON.stringify(obj),
                env:'test', 
                done: sendMsgDone,
            });
            setLastSendTime(currentTime);
        }
    };

    // 发送消息成功回调
    const sendMsgDone = (error, msg) => {
        console.log('发送聊天室' + msg.type + '消息' + (!error?'成功':'失败') + ', id=' + msg.idClient, error, msg);
        filterMessagesSend(msg,true);
        setInputValue('');
        setCountdown(limitTime);
        setIsSending(true);
    }

    useEffect(() => {
        if (scrolMsglRef.current) {
            scrolMsglRef.current.scrollTop = scrolMsglRef.current.scrollHeight;
        }
    }, [messages]);

    //用户输入
    const handleChange  = e => {
        let value = e.target?.value;
        
        if (value.length > 50) {
            value = value.slice(0, 50);
        }
        setInputValue(value);

    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); 
            handleSendMessage();
        }
        if(liveRoomInfo.liveType == 2)return
        if (e.key === '@') {
            setShowSelectBox(true);
        } else {
            setShowSelectBox(false);
        }
    };
    // 选择
    const handleSelect = (item) => {
        const atIndex = inputValue.lastIndexOf('@');
        if (atIndex!== -1) {
            const newInputValue = 
                inputValue.slice(0, atIndex) + 
                `@${item.agentName} ` + 
                inputValue.slice(atIndex + 1);
            setInputValue(newInputValue);
        }
        setShowSelectBox(false);
    }   


    // 处理倒计时
    useEffect(() => {
        if (isSending && countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);

            return () => clearTimeout(timer);
        } else if (isSending && countdown === 0) {
            setIsSending(false); // 倒计时结束，显示发送按钮
        }
    }, [isSending, countdown]);

    const handlePlayError = (playerId) => {
        console.log(`收到播放器 ${playerId} 播放失败的通知`);
        setLiveType(false);
      };

    const handleProgramClick = () => {
        setShowProgram(true);
    }
    const handleToLinkClick = () => {
        window.open(angetDetailUrl);
    }

    const handleOk = () => {
        setShowProgram(false);
    };
    const handleCancel = () => {
        setShowProgram(false);
    };

    //翻译
    const translateMsg = () => {
        AgentLiveApi.toTranslate({text:'你好',targetLanguage:'en_US'}).then(res => {
            if (res.data.code != 0) return;
            const data = res?.data?.data; 
            setImInfo({...data});
        }); 
    }
  
    return (
        <div className="live-room-container">
           

            <div className="select-container">
                <div className="select-wrapper">
                    <span className='agentTiltle' onClick={translateMsg}>{t('agent.title')}</span>
                </div>
                <div className='select-wrapper'>
                    {/* <div className='toLink' onClick={handleToLinkClick}>
                        <span>{t('agent.toLink')}</span>
                    </div> */}
                    <div className="program-list" onClick={handleProgramClick}>
                        <span>{t('agent.program')}</span>
                    </div>
                </div>
            </div>

            <div className="main-content">
             
                <div className="video-and-chat">
                    <div className="video-container">
                        {Object.keys(liveRoomInfo).length > 0 && liveType &&
                            <Aliplayer
                                key={liveRoomInfo?.roomUid}
                                id={'liveRoom'}
                                index={4}
                                streamUrl={liveRoomInfo?.pullStreamUrl}
                                isAutoplay={true}
                                onPlayError={handlePlayError}
                            />
                        }
                        {!liveType &&
                            <div className="live-cover">
                                <img src={i18n.language == 'en_US' ? liveCoverEn : liveCover} alt="" />
                            </div>
                        }
                    </div>

                    <div className="chat-container">
                        <div className="chat-title">
                            <span className="flex flex-1 border-[0.5px] border-[#F4F4F4CC]"></span>
                            {   i18n.language == 'en_US' ?
                                <span className="flex m-[5px] text-[#F4F4F4CC]">{t('agent.talk')}&nbsp;{t('agent.with')}&nbsp;FiveSs</span>
                                :
                                <span className="flex m-[5px] text-[#F4F4F4CC]">{t('agent.with')}FiveSs{t('agent.talk')}</span>
                            }
                            <span className="flex flex-1 border-[0.5px] border-[#F4F4F4CC]"></span>
                        </div>
                        <div 
                            className="chat-messages"
                            style={{scrollbarWidth: 'none',MsOverflowStyle: 'none'}}
                            ref={scrolMsglRef}
                        >
                            {messages.map((item,index) =>(
                                <div 
                                    className="message-item"
                                    key={index}
                                >
                                    <div className="message-avatar"><img className="w-[38px] h-[38px] rounded-[50%] border border-[#D597FFCC]" src={item.avatar} alt="" /></div>
                                    <div 
                                        className="message-text"
                                        style={{ 
                                            color: item.isSendMsg ? '#F642D7' : '#F4F4F4',
                                            wordWrap: 'break-word',
                                            wordBreak: 'break-all'
                                        }}
                                    >{item.text}</div>
                                </div>
                            ))}
                        </div>
                        {Object.keys(imInfo).length > 0 ? 
                            <div className="chat-input">
                                <input 
                                    onChange={e => { handleChange(e) }} 
                                    onKeyDown={handleKeyDown} 
                                    value={inputValue} 
                                    className="flex-1 h-[100%] text-[14px] placeholder-[#999797] placeholder:font-AudiowideRegular" 
                                    placeholder={ t('agent.sendTips') } />
                                {isSending ? (
                                    <span className="countdown">{countdown}s</span>
                                ) : (
                                    <span className="send-button" onClick={handleSendMessage} ><img className="w-[18px] h-[18px" src={snedIcon} alt="" /></span>
                                )}
                                {showSelectBox && 
                                    <div className="select-box">
                                        {liveRoomInfo?.roomAgents.map((item)=>(
                                        <div 
                                            className="select-item" 
                                            key={item.agentUid}
                                            onClick={()=> handleSelect(item)}
                                        >
                                            @{item.agentName}
                                        </div> 
                                    ))}
                                    </div>
                                }
                            </div>:
                            <div className="chat-input-noLogin">
                                <span className='loginTips'>{t('agent.toLogin')}</span>
                            </div>
                        }
                    </div>

                </div>

                <div className="bomAgentTips">FiveSs{t('agent.agentTips')}</div>               
                <div className='agentLiveRoom-right'>
                    <div className='toAiImg'><img src={i18n.language == 'en_US' ? toAiEn : toAi} onClick={handleToLinkClick} alt="" /></div>
                </div>            
            </div>


            <Modal title={t('agent.program')} open={showProgram} footer={null} onOk={handleOk} onCancel={handleCancel}>
                <div className='program-box'>
                    <table>
                        <thead>
                            <tr>
                                <th className='th'>{t('agent.type')}</th>
                                <th className='th'>{t('agent.sort')}</th>
                                <th className='thMusic'>{t('agent.music')}</th>
                                <th className='th'>{t('agent.join')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Opening</td>
                                <td>1</td>
                                <td>话题：欢迎来看公演+个人自我简介</td>
                                <td>ALL</td>
                            </tr>
                            <tr>
                                <td>Performance</td>
                                <td>2</td>
                                <td>AD062《虚构者》</td>
                                <td>ALL</td>
                            </tr>
                            <tr>
                                <td>Performance</td>
                                <td>3</td>
                                <td>AD069《黑夜女神》</td>
                                <td>ALL</td>
                            </tr>
                            <tr>
                                <td>Performance</td>
                                <td>4</td>
                                <td>AD036《春日》</td>
                                <td>ALL</td>
                            </tr>
                            <tr>
                                <td>Performance</td>
                                <td>5</td>
                                <td>AD039《雪之祭》</td>
                                <td>ALL</td>
                            </tr>
                            <tr>
                                <td>Talk</td>
                                <td>6</td>
                                <td>话题：简单聊一聊对本场公演的看法</td>
                                <td></td>
                                {/* <td>
                                    <div>{t('agent.takl1Text1')}</div>
                                    <div>{t('agent.takl1Text2')}</div>
                                    <div>{t('agent.takl1Text3')}</div>
                                    <div>{t('agent.takl1Text4')}</div>
                                    <div>{t('agent.takl1Text5')}</div>
                                    <div>{t('agent.takl1Text6')}</div>
                                    <div>{t('agent.takl1Text7')}</div>
                                    <div>{t('agent.takl1Text8')}</div>
                                    <div>{t('agent.takl1Text9')}</div>
                                    <div>{t('agent.takl1Text10')}</div>
                                    <div>{t('agent.takl1Text11')}</div>
                                </td> */}
                            </tr>
                            <tr>
                                <td>Performance</td>
                                <td>7</td>
                                <td>AD075《耳语者（whisper）》</td>
                                <td>ALL</td>
                            </tr>
                            <tr>
                                <td>Performance</td>
                                <td>8</td>
                                <td>AD093《Like a diamond》</td>
                                <td>ALL</td>
                            </tr>
                            <tr>
                                <td>Performance</td>
                                <td>9</td>
                                <td>AD101《关不掉》</td>
                                <td>ALL</td>
                            </tr>
                            <tr>
                                <td>Talk</td>
                                <td>10</td>
                                <td>话题：在生活中有些特别的习惯</td>
                                <td></td>
                                {/* <td>
                                    <div>{t('agent.takl2Text1')}</div>
                                    <div>{t('agent.takl2Text2')}</div>
                                    <div>{t('agent.takl2Text3')}</div>
                                    <div>{t('agent.takl2Text4')}</div>
                                    <div>{t('agent.takl2Text5')}</div>
                                    <div>{t('agent.takl2Text6')}</div>
                                    <div>{t('agent.takl2Text7')}</div>
                                    <div>{t('agent.takl2Text8')}</div>
                                    <div>{t('agent.takl2Text9')}</div>
                                    <div>{t('agent.takl2Text10')}</div>
                                    <div>{t('agent.takl2Text11')}</div>
                                </td> */}
                            </tr>
                            <tr>
                                <td>Performance</td>
                                <td>11</td>
                                <td>AD033《人间规则》</td>
                                <td>ALL</td>
                            </tr>
                            <tr>
                                <td>Performance</td>
                                <td>12</td>
                                <td>AD139《爱未央》</td>
                                <td>ALL</td>
                            </tr>
                            <tr>
                                <td>Performance</td>
                                <td>13</td>
                                <td>AD014《木偶》</td>
                                <td>ALL</td>
                            </tr>
                            <tr>
                                <td>Danmu</td>
                                <td>14</td>
                                <td>话题：最喜欢的团员特质是什么</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Performance</td>
                                <td>15</td>
                                <td>AD130《废墟纪元》</td>
                                <td>ALL</td>
                            </tr>
                            <tr>
                                <td>Performance</td>
                                <td>16</td>
                                <td>AD089《美丽世界》</td>
                                <td>ALL</td>
                            </tr>
                            <tr>
                                <td>Performance</td>
                                <td>17</td>
                                <td>AD109《花园舞曲》</td>
                                <td>ALL</td>
                            </tr>
                            <tr>
                                <td>Performance</td>
                                <td>18</td>
                                <td>AD082《光之轨迹》</td>
                                <td>ALL</td>
                            </tr>
                            <tr>
                                <td>Talk</td>
                                <td>19</td>
                                <td>话题：按你的标准给场上队员排序</td>
                                <td></td>
                                {/* <td>
                                    <div>{t('agent.talk3Text1')}</div>
                                    <div>{t('agent.talk3Text2')}</div>
                                    <div>{t('agent.talk3Text3')}</div>
                                    <div>{t('agent.talk3Text4')}</div>
                                    <div>{t('agent.talk3Text5')}</div>
                                    <div>{t('agent.talk3Text6')}</div>
                                    <div>{t('agent.talk3Text7')}</div>
                                    <div>{t('agent.talk3Text8')}</div>
                                    <div>{t('agent.talk3Text9')}</div>
                                    <div>{t('agent.talk3Text10')}</div>
                                    <div>{t('agent.talk3Text11')}</div>
                                    <div>{t('agent.talk3Text12')}</div>
                                    <div>{t('agent.talk3Text13')}</div>
                                    <div>{t('agent.talk3Text14')}</div>
                                    <div>{t('agent.talk3Text15')}</div>
                                    <div>{t('agent.talk3Text16')}</div>
                                </td> */}
                            </tr>
                            <tr>
                                <td>Performance</td>
                                <td>20</td>
                                <td>AD132《怦然心动》</td>
                                <td>ALL</td>
                            </tr>
                            <tr>
                                <td>Performance</td>
                                <td>21</td>
                                <td>AD116《晨光》</td>
                                <td>ALL</td>
                            </tr>
                            <tr>
                                <td>Performance</td>
                                <td>22</td>
                                <td>AD099《梦想的旗帜》</td>
                                <td>ALL</td>
                            </tr>
                            <tr>
                                <td>Danmu</td>
                                <td>23</td>
                                <td>话题：最打动你的一首公演曲</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Performance</td>
                                <td>24</td>
                                <td>AD021《她和她》</td>
                                <td>ALL</td>
                            </tr>
                            <tr>
                                <td>Performance</td>
                                <td>25</td>
                                <td>AD064《梦与星光的海上》</td>
                                <td>ALL</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Modal>          

        </div>
    );
  };

export default LiveRoom;
